import Header from './components/Header';
import Footer from './components/Footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import CustomModal from './components/CustomModal';

function Users() {
  const [UserData, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [addMode, setAddMode] = useState(true);
  const [rowData, setRowData] = useState({});
  const [selectedUser, setSelectedUser] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const [filterValue, setFilterValue] = useState('');

  const filteredUserData = UserData.filter(user => {
    const searchString = `${user.ITS} ${user.FirstPrefix} ${user.FirstName} ${user.LastName} ${user.Name} ${user.PhoneNumber} ${user.Email} ${user.Address} ${user.IsMember} ${user.RoleName}`.toLowerCase();
    return searchString.includes(filterValue.toLowerCase());
  });

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };


  const fetchUsers = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getAllUsersData`, {});
      if (response.status === 200) {
        if (response.data.success === false) {
          setUserData([]);
        } else {
            console.log(response.data);
          setUserData(response.data);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleEdit = (data) => {
    setAddMode(false);
    setRowData(data);
    setSelectedUser(data.ITS);
    setShowModal(true);
  };

  const handleAddNewUser = () => {
    setAddMode(true);
    setRowData({});
    setSelectedUser('');
    setShowModal(true);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setRowData({});
    setSelectedUser('');
    setValidationMessage('');
  };
  const updateUser = async (ITS, FirstPrefix, FirstName, LastName, Name, PhoneNumber, Email, Address, IsMember, UserId) => {
   // console.log(ITS, FirstPrefix, FirstName, LastName,Name, PhoneNumber, Email, Address, IsMember, UserId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateUser`,
                                          {ITS, FirstPrefix, FirstName, LastName, Name, PhoneNumber, Email, Address, IsMember, UserId });
      if (response.data.success) {
        fetchUsers();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleSave = (updatedData) => {
    console.log("handleSave", updatedData)
    if (updatedData.ITS === null || updatedData.ITS === undefined || updatedData.ITS === '') {
      setValidationMessage('ITS is required');
      return;
    }
    setValidationMessage('');
    updateUser(updatedData.ITS, updatedData.FirstPrefix, updatedData.FirstName, updatedData.Name, updatedData.LastName,updatedData.PhoneNumber, updatedData.Email, updatedData.Address, updatedData.IsMember, updatedData.UserId);
    setShowModal(false);
    setRowData({});
  };
  const handleSaveNew = (updatedData) => {
    console.log("handleSaveNew", updatedData)
    if (updatedData.ITS === null || updatedData.ITS === undefined || updatedData.ITS === '') {
      setValidationMessage('ITS is required');
      return;
    }
    setValidationMessage('');
    addUser(updatedData.ITS, updatedData.FirstPrefix, updatedData.FirstName, updatedData.LastName, updatedData.Name,updatedData.PhoneNumber, updatedData.Email, updatedData.Address, updatedData.IsMember, updatedData.UserId);
    setShowModal(false);
    setRowData({});
  };

  const addUser = async (ITS, FirstPrefix, FirstName, LastName, Name, PhoneNumber, Email, Address, IsMember, UserId) => {
   // console.log(ITS, FirstPrefix, FirstName, LastName,Name, PhoneNumber, Email, Address, IsMember, UserId)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNewUser`, { ITS, FirstPrefix, FirstName, LastName, Name, PhoneNumber, Email, Address, IsMember, UserId });
      if (response.data.success) {
        fetchUsers();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '70px', paddingBottom: '70px', overflowY: 'auto' }}>
        <Container style={{ width: '100%', maxWidth: '1100px', padding: '0 15px' }}>
          <Row className="mt-4">
          <Col xs={6}>
              <input
                type="text"
                style={{float:'left'}}
                placeholder="Search..."
                value={filterValue}
                onChange={handleFilterChange}
              />
            </Col>
            <Col xs={6} style={{ textAlign: 'right', marginBottom: '15px' }}>
              <Button variant="contained" style={{ backgroundColor: 'green' }} onClick={handleAddNewUser}>Add New User</Button>
            </Col>
          </Row>
          <Row>
            
          </Row> {/*User Data table*/}
          {UserData && UserData.length > 0 ? (
            <Row>
              <Col xs={12}>
                <div style={{ overflowX: 'auto' }}>
                  <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px', minWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th>ITS</th>
                        <th>First Prefix</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Is Member</th>
                        {/* <th>Role</th> */}
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                    {filteredUserData.map(user => (
                        <tr key={user.UserId}>
                          <td>{user.ITS}</td>
                          <td>{user.FirstPrefix}</td>
                          <td>{user.FirstName}</td>
                          <td>{user.LastName}</td>
                          <td>{user.Name}</td>
                          <td>{user.PhoneNumber}</td>
                          <td>{user.Email}</td>
                          <td>{user.Address}</td>
                          <td>{user.IsMember ? 'Yes' : 'No'}</td>
                          {/* <td>{user.RoleName}</td> */}
                          <td>
                            <Button variant="primary" onClick={() => handleEdit(user)} startIcon={<EditIcon />}></Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
             Data Unavailable...
            </Row>
          )}
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{addMode ? 'Add New User' : 'Edit User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                {/* <div className="mb-3">
                  <label htmlFor="UserId" className="form-label">User Id</label>
                  {rowData.UserId !== null && rowData.UserId !== undefined ? (
                        <input
                        type="text"
                        className="form-control"
                        id="UserId"
                        disabled= "true"
                        value={rowData.UserId}
                        onChange={(e) => setRowData({ ...rowData, UserId: e.target.value })}
                        required
                        />
                    ) : (
                        <input
                        type="text"
                        className="form-control"
                        id="UserId"
                        value=""
                        disabled= "true"
                        onChange={(e) => setRowData({ ...rowData, UserId: e.target.value })}
                        required
                        />
                    )}
                </div> */}
                {/* <div className="mb-3">
                  <label htmlFor="ITS" className="form-label">ITS <span style={{ color: 'red' }}> *</span></label>
                  <input type="text" className="form-control" id="ITS" value={rowData.ITS || ''} onChange={(e) => setRowData({ ...rowData, ITS: e.target.value })} />
                  {validationMessage && <div className="text-danger">{validationMessage}</div>}
                </div> */}
                <div className="mb-3">
                  <label htmlFor="ITS" className="form-label">ITS</label>
                  <input type="text" className="form-control" id="ITS" value={rowData.ITS || ''} onChange={(e) => setRowData({ ...rowData, ITS: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="FirstPrefix" className="form-label">First Prefix</label>
                  <input type="text" className="form-control" id="FirstPrefix" value={rowData.FirstPrefix || ''} onChange={(e) => setRowData({ ...rowData, FirstPrefix: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="FirstName" className="form-label">First Name</label>
                  <input type="text" className="form-control" id="FirstName" value={rowData.FirstName || ''} onChange={(e) => setRowData({ ...rowData, FirstName: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="FirstPrefix" className="form-label">Last Name</label>
                  <input type="text" className="form-control" id="FirstPrefix" value={rowData.LastName || ''} onChange={(e) => setRowData({ ...rowData, LastName: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="Name" className="form-label">Name</label>
                  <input type="text" className="form-control" id="Name" value={rowData.Name || ''} onChange={(e) => setRowData({ ...rowData, Name: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="PhoneNumber" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="PhoneNumber" value={rowData.PhoneNumber || ''} onChange={(e) => setRowData({ ...rowData, PhoneNumber: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="Email" className="form-label">Email</label>
                  <input type="text" className="form-control" id="Email" value={rowData.Email || ''} onChange={(e) => setRowData({ ...rowData, Email: e.target.value })} />
                </div>
                <div className="mb-3">
                <label htmlFor="IsMember" className="form-label">IsMember</label>
                <div className="form-check form-switch">
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="IsMember" 
                    checked={rowData.IsMember} 
                    onChange={(e) => setRowData({ ...rowData, IsMember: e.target.checked })} 
                    />
                    <label className="form-check-label" htmlFor="IsMember">{rowData.IsMember ? 'True' : 'False'}</label>
                </div>
                </div>

                {/* <div className="mb-3">
                  <label htmlFor="RoleName" className="form-label">RoleName</label>
                  <input type="text" className="form-control" id="Address" value={rowData.RoleName || ''} onChange={(e) => setRowData({ ...rowData, RoleName: e.target.value })} />
                </div> */}
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
              <Button variant="primary" onClick={() => addMode ? handleSaveNew({ ...rowData, UserId: '' }) : handleSave({ ...rowData })}>{addMode ? 'Add' : 'Save changes'}</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <Footer />
    </>
  );


}

export default Users;
