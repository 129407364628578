import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import CustomModal from "../components/CustomModal";
import CircularProgress from '@mui/material/CircularProgress';

function AdminReport() {
  const [AdminReport, setAdminReport] = useState([]);
  const [reportType, setReportType] = useState(null);
  const [message, setMessage] = useState('');
  const [modalType, setModalType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  const fetchData = async (type) => {
    let url;
    setAdminReport([]); // Reset AdminReport state after download
    if (type === "an") {
      url = '/api/getAdminReport';
    } else if (type === "ck") {
      url = '/api/getCookReport';
    } else if (type === "dd") {
      url = '/api/getDeliveryReport';
    } else if (type === "zi") {
      url = '/api/getZonalInchargeReport';
    }
    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + url, {});
      if (response.status === 200) {
        if (response.data.success === false) {
          setMessage(true);
          setLoading(false);
          setAdminReport([]);
          handleOpenModal('report');
        } else {
          setReportType('');
          setAdminReport([]);
          setAdminReport(response.data);
          setMessage(response.data.message);
          setReportType(type); // Set the report type here
        }
      } else {
        setMessage(response.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    if (reportType) {
      downloadExcel(reportType);
    }
  }, [reportType]);

  const calculateColumnWidths = (data) => {
    const colWidths = [];
    const keys = Object.keys(data[0]);
    keys.forEach((key) => {
      const maxLength = data.reduce((max, row) => Math.max(max, row[key] ? row[key].toString().length : 0), key.length);
      colWidths.push({ wch: maxLength + 2 }); // Add some padding to the width
    });
    return colWidths;
  };

  const downloadExcel = (type) => {
    if(type != '')
      {
        setLoading(true);
        let fileName;
        if (type === "an") {
          fileName = "AdminReport.xlsx";
        } else if (type === "ck") {
          fileName = "CookReport.xlsx";
        } else if (type === "dd") {
          fileName = "DeliveryReport.xlsx";
        } else if (type === "zi") {
          fileName = "ZonalInchargeReport.xlsx";
        }
        if (AdminReport != null && AdminReport.length > 0) {
          const formattedData = [];
          let previousDate = null;     
          // Define options for date formatting
         // console.log(AdminReport);
          const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
          AdminReport.forEach(item => {
            delete item.FoodItemNumber;
            delete item.Priority;            ;
            if ((type === 'ck' || type === 'zi') && item.Date !== previousDate) {
              if(previousDate!== null)
                formattedData.push({}) 
              previousDate = item.Date;
            }  
            formattedData.push(item);
          });
          //console.log(AdminReport);
          const ws = XLSX.utils.json_to_sheet(formattedData);
          const colWidths = calculateColumnWidths(formattedData);
          ws['!cols'] = colWidths;
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
          XLSX.writeFile(wb, fileName);
        }
        setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CircularProgress style={{ color: 'green' }} />
        </div>
      )}
      <Header />
      <div>
        {modalType === 'report' && (
          <CustomModal
            open={modalOpen}
            onClose={handleCloseModal}
            modalType="report"
            onNoClick={handleCloseModal}
          />
        )}
      </div>
      <div>
        <Container className="mt-5">
          <br></br>
          <Row><h3>Admin's Report</h3></Row>
          <Row className="justify-content-center">
            <Col md="auto" className="mb-3 mb-md-0">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={() => fetchData("an")}> Generate Admin Report </Button>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={() => fetchData("ck")}> Generate Cook Report </Button>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={() => fetchData("zi")}> Generate Zonal Incharge Report </Button>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={() => fetchData("dd")}> Generate Delivery Driver Report </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default AdminReport;
